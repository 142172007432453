import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import OASection from "../components/our-approach-section";
import blueOne from "../images/1-blue.svg";
import blueTwo from "../images/2-blue.svg";
import blueThree from "../images/3-blue.svg";

const PWAPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
        altText
        mediaDetails {
          height
          width
        }
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      img1: wpMediaItem(title: { eq: "Web-Design-New" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      pwa1: wpMediaItem(title: { eq: "pwa-1" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      pwa2: wpMediaItem(title: { eq: "pwa-2" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      capitalImg: wpMediaItem(title: { eq: "capitaltocoast-fullpage" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      hiveImg: wpMediaItem(title: { eq: "Hivehr-fullpage" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      googleAImg: wpMediaItem(title: { eq: "google-analytics-new" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      googleSearchImg: wpMediaItem(title: { eq: "google-search" }) {
        altText
        link
        gatsbyImage(
          width: 1920
          quality: 75
          formats: [WEBP]
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
      wpSeoPage(title: { eq: "Progressive Web App" }) {
        contentForHeadTag {
          content
        }
        seo {
          metaDesc
          title
          twitterImage {
            altText
            mediaDetails {
              height
              width
            }
            link
            gatsbyImage(
              width: 1920
              quality: 75
              formats: [WEBP]
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
          opengraphTitle
          opengraphDescription
        }
      }
      icon: wpMediaItem(title: { eq: "icon" }) {
        link
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const schema = data.wpSeoPage.contentForHeadTag.content;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Progressive Web App Development Agency",
        item: {
          url: `${siteUrl}/progressive-web-app-development-agency`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        {" "}
        <script type="application/ld+json">{schema}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={data.wpSeoPage.seo.title}
        description={data.wpSeoPage.seo.metaDesc}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/progressive-web-app-development-agency`,
          title: `${data.wpSeoPage.seo.opengraphTitle}`,
          description: `${data.wpSeoPage.seo.opengraphDescription}`,
          images: [
            {
              url: `${data.wpSeoPage.seo.twitterImage.link}`,
              width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
              height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
              alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <section>
          <Hero
            button1="primary-link-dark"
            button1Text="Talk to an expert"
            button1Link="#form"
            button1Var="white"
            // button2="white-link-yellow"
            // button2Text="Pricing"
            // button2Link="#pricing"
            // button2Var="primary"
            button2={null}
            button3={null}
            backgroundImage={data.heroImg.gatsbyImage}
						title={`PROGRESSIVE \n<span class="text-secondary">WEB APP</span> \nDEVELOPMENT AGENCY`}
            description="Building progressive web apps for businesses."
          />
        </section>
        {/* <Logos /> */}
        {/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Delivering results
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#pricing"
								>
									Pricing
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
        <section id="intro" className="bg-light-grey py-5 py-lg-7">
          <Container>
            <Row className="align-items-center gx-lg-6 gx-xl-7">
              <Col lg={6} className=" mb-5 mb-lg-0 ">
                <h2 className="display-5 text-primary mb-5">
                  Creating apps without the hassle of the app stores
                </h2>
                <div
                  className="d-lg-none mb-4"
                  style={{ overflow: "hidden", borderRadius: "15px" }}
                >
                  <GatsbyImage
                    className="w-100"
                    image={data.pwa1.gatsbyImage}
                    alt={data.pwa1?.altText}
                  />
                </div>
                <p>
                  Progressive Web Apps (PWAs) are advanced web applications that
                  offer a seamless and engaging experience, similar to the
                  native applications you would have on your phone. They load
                  quickly, work offline, and have the majority of the
                  functionality of a native mobile app.
                </p>
                <p>
                  For businesses, PWAs allow for a better customer journey than
                  on your ordinary website, are more cost-effective, and can
                  achieve a wider reach. Progressive web apps offer a smooth
                  user experience, eliminate the need for separate third party
                  app development, and can be easily discovered through search
                  engines.
                </p>

                <Button
                  className=" cta-btn w-100 d-none d-xl-inline-block w-md-auto mt-4 me-md-4 fs-5 white-link-yellow py-2 px-4 "
                  variant="primary"
                  href="#form"
                >
                  Talk to an expert today
                </Button>
                {/* <Button
									className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href="#pricing"
								>
									Website pricing
								</Button> */}
              </Col>
              <Col className="d-none d-lg-block" lg={6}>
                <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                  <GatsbyImage
                    className="w-100"
                    image={data.pwa1.gatsbyImage}
                    alt={data.pwa1?.altText}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
          <Container className="">
            <Row>
              <Col>
                <h2 className="display-2 ssp-bold text-white text-uppercase mb-5">
                  Advantages of
                  <br /> having a{" "}
                  <span>
                    <span className="text-light-blue">PWA</span>
                  </span>
                </h2>
              </Col>
            </Row>
            <Row className="gx-xl-9 g-6 justify-content-center">
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute text-center w-100 h-100"
                >
                  <img src={blueOne} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5  position-relative">
                  <h3 className="fs-1 text-white">Hassle-free</h3>
                  <p className="text-white">
                    Create an app without having to wait forever for the app
                    store to approve it and take payments from customers without
                    Google or Apple taking a large cut through in-app payments.
                  </p>
                </div>
              </Col>
              <Col className="position-relative" md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute text-center w-100 h-100"
                >
                  <img src={blueTwo} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5  position-relative">
                  <h3 className="fs-1 text-white">Good for SEO</h3>
                  <p className="text-white">
                    Optimise each of your app pages for search engine
                    optimisation (SEO) to allow you to rank higher on search
                    engine results pages.
                  </p>
                </div>
              </Col>
              <Col className="position-relative " md={6} lg={4}>
                <div
                  style={{ top: "-7rem" }}
                  className="position-absolute w-100 text-center h-100"
                >
                  <img src={blueThree} className="w-md-40 w-30" />
                </div>
                <div className="px-xl-5 pt-5  position-relative">
                  <h3 className="fs-1 text-white">Responsive</h3>
                  <p className="text-white">
                    Progressive web apps are responsive on all devices allowing
                    for a greater user experience whether viewing it on a
                    laptop, tablet or phone.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="what-we-use" className="bg-white py-5 py-lg-7">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} className=" ">
                <h2 className=" text-primary mb-5">Why use React</h2>
                <p>
                  React is specifically designed for apps that have complex user
                  interfaces, optimised for performance, large active community
                  with third-party libraries to help to speed up the development
                  of your PWA and has integration with many popular tools and
                  third-party applications available on the market.
                </p>

                <p>
                  RJM Digital are experts in React and have successfully created
                  more than 50 PWAs for satisfied customers.{" "}
                </p>
              </Col>
              <Col className="d-none d-lg-block" lg={6}>
                <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                  <GatsbyImage
                    className="w-100"
                    image={data.pwa2.gatsbyImage}
                    alt={data.pwa2?.altText}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-5 py-lg-7  bg-light-grey">
          <Container>
            <Row id="form">
              <Col className="text-center">
                {" "}
                <h2 className="display-5 text-primary mb-3">
                  Talk to an expert
                </h2>
                <p>
                  Book a time and day that works for you through our online
                  calendar to discuss your PWA project.
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="d-xl-none">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "1150px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
              <Col className="d-none d-xl-block">
                <InlineWidget
                  className="calender"
                  styles={{ minWidth: "100%", height: "700px" }}
                  url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <div id="approach">
          <OASection
            buttonUrl="#form"
            buttonText="Talk to an expert"
            image={null}
            launch="After we have meticulously checked all the content and the functions of your new app are working and worded perfectly, we are able to launch your new progressive web app! Our support team will be on hand whenever you have any questions or need any changes made to your app. "
            build="Once the design has been approved, the RJM Digital team of developers can begin to build your progressive web app. You are able to keep track of your apps progress on our test server and we will ask you to review your new progressive web app before we launch."
            design="We will then begin designing the progressive web app. Ensuring we incorporate your brand into the design and build it to be easily accessible with a good user experience for your customers. At this stage you are able to make as many alterations as you deem necessary until you are happy with the design."
            brief="Our expert term will work with you throughout the entire process of building your new progressive web app. We will fully understand your needs and requirements regarding the PWA before moving onto the next stage of the process. The final result will be a new progressive web app that represents your brand and your values, helping to improve your marketing efforts."
          />
        </div>

        {/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Our Web Services" />
				</section>
				<div id="faq">
					<Faq page="Web Design" />
				</div> */}
        <OSSection
          link3="/sustainable-web-design"
          text3="SUSTAINABLE WEBSITES"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1?.altText}
        />
      </Layout>
    </>
  );
};

export default PWAPage;
